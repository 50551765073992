import React, { ReactNode, useCallback, useState } from 'react'
import classNames from 'classnames'
import upperFirst from 'lodash.upperfirst'

import Icon from 'components/Icon'
import styles from './Alert.module.css'

export type AlertProps = {
  className?: string
  titleClassName?: string
  children: React.ReactNode
  icon?: string
  iconSize?: number
  iconColor?: string
  kind?: 'default' | 'info' | 'success' | 'error' | 'warning' | 'hint'
  color?: 'green' | 'blue' | 'red' | 'yellow' | 'purple' | 'gray' | 'tan'
  title?: string | ReactNode
  condensed?: boolean
  dismissable?: boolean
  hasBar?: boolean
  hideIcon?: boolean
  onDismiss?: () => void
}

const kindToDefaultIcon = {
  default: 'alert-circle',
  info: 'alert-circle',
  error: 'alert-circle',
  success: 'check-circle',
  warning: 'alert-triangle',
  hint: 'file-text'
}

const kindToDefaultColor = {
  default: 'gray',
  info: 'blue',
  error: 'red',
  success: 'green',
  warning: 'yellow',
  hint: 'green'
}

const kindToIconColor = {
  default: 'var(--colorBlue8)',
  info: 'var(--alertBlueIconColor)',
  error: 'var(--alertRedIconColor)',
  success: 'var(--alertGreenIconColor)',
  warning: 'var(--alertYellowIconColor)',
  hint: 'var(--alertGreenIconColor)'
}

const colorToIconColor = {
  blue: 'var(--alertBlueIconColor)',
  red: 'var(--alertRedIconColor)',
  green: 'var(--alertGreenIconColor)',
  yellow: 'var(--alertYellowIconColor)',
  purple: 'var(--alertPurpleIconColor)',
  gray: 'var(--alertBlue8)',
  tan: 'var(--alertTanIconColor)'
}

const Alert = ({
  className,
  titleClassName,
  children,
  title,
  condensed = false,
  dismissable = false,
  onDismiss,
  hasBar = false,
  kind = 'default',
  color,
  icon,
  iconSize = 16,
  iconColor,
  hideIcon = false
}: AlertProps) => {
  const [dismissed, setDismissed] = useState(false)

  const onDismissClick = useCallback(() => {
    setDismissed(true)
    onDismiss?.()
  }, [onDismiss])

  return dismissed ? (
    <></>
  ) : (
    <div
      className={classNames(styles.alert, styles[color || kindToDefaultColor[kind]], styles[kind], className, {
        [styles.condensed]: condensed,
        [styles.dismissable]: dismissable,
        [styles.hasBar]: hasBar
      })}>
      <div className={styles.alertInner}>
        {!hideIcon && (
          <Icon
            className={styles.alertIcon}
            kind={icon || kindToDefaultIcon[kind]}
            size={iconSize}
            color={iconColor || (color && colorToIconColor[color]) || kindToIconColor[kind]}
          />
        )}

        <div className={styles.alertRight}>
          {title && (
            <div className={classNames(styles.alertTitle, styles[`alertTitle${upperFirst(kind)}`], titleClassName)}>
              
            </div>
          )}
          <div className={styles.alertText}>{children}</div>
        </div>
      </div>

      {dismissable && <Icon kind="x" size={10} onClick={() => onDismissClick()} className={styles.dismiss} />}
    </div>
  )
}

export default Alert
